import React from 'react';
import Seo from '../../../components/Seo';
import MarginContainer from '../../../components/MarginContainer';
import PageContentWrapper from '../../../components/pageElements/PageContentWrapper';
import Header from './header';
import Body from './body';

function ProductsPage() {

  return (
    <>
      <Seo title="Solutions" />
      <PageContentWrapper>
        <MarginContainer>
          <Header/>
          <Body/>
        </MarginContainer>
      </PageContentWrapper>
    </>
  );
}

export default ProductsPage;
